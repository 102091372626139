module.exports = [{
      plugin: require('C:/Users/Adrian/Documents/ThunderLandings/Blog/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Adrian/Documents/ThunderLandings/Blog/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Adrian/Documents/ThunderLandings/Blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
